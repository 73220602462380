
.train-detail-content {
    flex: 1;
    width: 1%;
    display: flex;
    flex-direction: column;
    .train-detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        ::v-deep .el-button--primary {
            padding: 10px 25px;
            color: #FFF;
            background-color: #1E33E4;
            border-color: #1E33E4;
        }
        .interactive-breadcrumb {
            margin-bottom: 0;
        }
    }
    .train-detail-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 1%;
        background-color: #fff;
        padding-left: 20px;
        ::v-deep .el-scrollbar {
            height: 100%;
        }
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .body-header {
            height: 72px;
            line-height: 72px;
            border-bottom: 1px solid #E5E5E5;
            margin-right: 20px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 500;
            color: #333;
            span.text {
                display: block;
                width: 100%;
                height: 100%;
                padding: 0 20px 0 0;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
            }
        }
        .task-info {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            margin-right: 20px;
            border-bottom: 1px solid #E5E5E5;
            ul {
                font-size: 14px;
                color: #666666;
                li {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .task-require {
            padding: 20px 0;
            margin-right: 20px;
            border-bottom: 1px solid #E5E5E5;
            .title {
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: #1E33E4;
                    margin-right: 6px;
                }
            }
            .require-content {
                color: #666;
                font-size: 14px;
            }
        }
        .task-annex {
            padding: 20px 0;
            margin-right: 20px;
            border-bottom: 1px solid #E5E5E5;
            .title {
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: #1E33E4;
                    margin-right: 6px;
                }
            }
            .annex-content {
                display: flex;
                justify-content: start;
                flex-wrap: wrap;
                li {
                    margin-bottom: 15px;
                    width: 50%;
                    .file-name {
                        float: left;
                        display: block;
                        max-width: 300px;
                        margin-right: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                        border-bottom:1px solid transparent;
                        i {
                            color: #409EFF;
                        }
                    }
                    .down-progress {
                        display: flex;
                        align-items: center;
                        .download {
                            color: #2338E6;
                            width: 50px;
                            margin-right: 20px;
                            cursor: pointer;
                            border-bottom:1px solid transparent;
                            .download-bg {
                                display: block;
                                width: 20px;
                                height: 20px;
                                background-color: #409EFF;
                                color: #fff;
                                border-radius: 4px;
                                line-height: 20px;
                                text-align: center;
                                i {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .script-name {
                    cursor: pointer;
                    border-bottom:1px solid transparent;
                    &:hover {
                        border-bottom:1px solid #2338E6;
                        color: #2338E6;
                    }
                }
            }
        }
        .train-evaluete {
            padding: 20px 0;
            margin-right: 20px;
            .title {
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: #1E33E4;
                    margin-right: 6px;
                }
            }
        }
    }
}
::v-deep.annex-list-dialog {
    border-radius: 6px;
    .el-dialog__header{
        display: none;
    }
    ::v-deep .el-dialog__body {
        padding: 30px;
    }
}
.view-script-main{
    display: flex;
    flex-direction: column;
    height: 100%;
    //::v-deep .el-scrollbar__wrap {
    //  overflow-x: hidden;
    //}
    .view-script-item-top{
        display: flex;
        //align-items: center;
        .view-script-img{
            width:390px;
            height:224px;
            margin-right:30px;
            img{
                max-width:390px;
                max-height:224px;
                border-radius: 10px;
            }
        }
        .view-script-right{
            flex: 1;
            width: 1%;
            .view-script-name{
                margin-top: 10px;
                font-size: 18px;
                color: #333;
            }
            .view-script-fenlei{
                font-size: 16px;
                color: #666;
                margin-top: 30px;
            }
            .view-script-jianjie{
                font-size: 16px;
                color: #666;
                margin-top: 8px;
                line-height: 30px;
            }
        }
    }
    .view-script-body{
        display: flex;
        flex-direction: column;
        border: 2px solid #584EEE;
        //box-shadow: 0px 10px 0px 0px #ECEBFF;
        border-radius:20px;
        margin:48px 0 30px;
        .view-script-body-title{
            width: 160px;
            height: 32px;
            background: #584EEE;
            border-radius: 16px;
            line-height: 32px;
            text-align: center;
            color: #fff;
            margin: -12px 0 0 24px;
            position: relative;
            &:before{
                content: "";
                background: #fff;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 12px;
                left: 20px
            }
            &:after{
                content: "";
                background: #fff;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 12px;
                right: 20px
            }
        }
        .view-script-body-content{
            display: flex;
            min-height: 100px;
            padding-left: 22px;
        }
    }

    ::v-deep.el-table{
        .warning-row {
            background: #ECEBFF;
        }
    }
    .dialog-footer {
        text-align: center;
        .el-button {
            padding: 10px 36px;
            margin-bottom: 0;
        }
        .el-button--primary {
            background-color: #2338E6;
            border: 1px solid #2338E6;
            &:hover {
                background-color: #1b2dbf;
                border: 1px solid #1b2dbf;
            }
        }
    }
}
.script-dialog-table{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    height: 265px;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .dialog-table-row{
        display: flex;
        &:nth-child(odd){
            background: #DEDCFD;
        }
        &:nth-child(even){
            background: #F8F7FF;
        }
        &:nth-child(1){
            .dialog-table-column{
                font-size: 14px;
                color: #222222;
                font-weight: 400;
            }
        }
        .dialog-table-column{
            display: flex;
            flex: 1;
            width: 1%;
            justify-content: center;
            align-items: center;
            height: 50px;
        }
    }
}
